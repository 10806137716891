<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-margin uk-child-width-1-4" uk-grid>
            <div>
                <div class="uk-form-controls">
                    <input
                        class="uk-input"
                        type="text"
                        placeholder="Type Contract Title here"
                        v-model="meta.title"
                        @keyup.enter="getInitContracts"
                    >
                </div>
            </div>
            <div class="uk-width-1-6">
                <select
                    name="type"
                    class="uk-select"
                    v-model="meta.type"
                    @change="getInitContracts"
                >
                <option :value="null">Select Type</option>
                <option value="1" selected>Myrobin Contract</option>
                <option value="2" >Company Contract</option>
                </select>
            </div>
            <div class="uk-width-1-6">
                <select
                    name="type"
                    class="uk-select"
                    v-model="meta.status"
                    @change="getInitContracts"
                >
                <option :value="null">Select Status</option>
                <option value="1">Draft</option>
                <option value="2" >Post</option>
                </select>
            </div>
            <div class="uk-inline">
                <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="$router.push({ name: 'Contract-create' })">Create</button>
            </div>
        </div>

        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <tr>
                            <th class="uk-text-center uk-table-shrink">No</th>
                            <th class="uk-text-center">Title</th>
                            <th class="uk-text-center uk-width-auto">Contract</th>
                            <th class="uk-text-center uk-width-auto">Status</th>
                            <th class="uk-text-center">Action</th>
                        </tr>
                    </thead>
                    <loading-table v-if="isLoading" :colspan="5"/>
                    <tbody v-else-if="contracts.docs.length>0">
                        <tr v-for="(contract, i) in contracts.docs" :key="i">
                            <td class="uk-text-center">{{ ((meta.page-1)*meta.limit)+i+1 }}</td>
                            <td class="uk-text-center">{{ contract.title }}</td>
                            <td class="uk-text-center">{{ preview(contract.contract) }}</td>
                            <td class="uk-text-center"><span :class="statusLabelClass(contract)">{{ statusLabel(contract) }}</span></td>
                            <td class="uk-text-center">
                                <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                                <div uk-dropdown>
                                <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                    <li><a @click="showModal('previewModal', contract)">Preview</a></li>
                                    <li class="uk-nav-divider"></li>
                                    <li><a @click="$router.push({ name: 'Contract-edit', params: { id: contract._id }})">Edit</a></li>
                                </ul>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="5"/>
                </table>
            </div>
            <pagination
                v-if="contracts && contracts.totalDocs"
                :total-data="contracts.totalDocs"
                :limit="meta.limit"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="detailModal" class="uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog" uk-overflow-auto>
                <button class="uk-modal-close-full uk-close-large" type="button" uk-close @click="hideModal('detailModal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Contract Detail</h2>
                </div>
                <div v-if="selected_contract != null" class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Title</label>
                        <input
                            class="uk-input"
                            type="text"
                            disabled
                            v-model="selected_contract.title">
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Type</label>
                        <select
                            name="type"
                            class="uk-select"
                            disabled
                            v-model="selected_contract.type"
                        >
                        <option :value="null">Select Type</option>
                        <option value="1" selected>Myrobin Contract</option>
                        <option value="2" >Company Contract</option>
                        </select>
                    </div>
                    <div class="uk-margin" v-if="selected_contract.type == 2">
                        <label class="uk-form-label">Company</label>
                        <input
                            class="uk-input"
                            type="text"
                            disabled
                            v-model="selected_contract.company_id.name">
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Status</label>
                        <select
                            name="type"
                            class="uk-select"
                            disabled
                            v-model="selected_contract.status"
                        >
                        <option :value="null">Select Status</option>
                        <option value="1">Draft</option>
                        <option value="2" >Post</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Contract</label>
                        <div v-html="selected_contract.contract"></div>
                    </div>
                </div>
            </div>
        </div>

        <div id="contractModal" class="uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog" uk-overflow-auto>
                <button class="uk-modal-close-full uk-close-large" type="button" uk-close @click="isEdit ? hideModal('editModal') : hideModal('createModal')"></button>
                <div class="uk-modal-header">
                    <div class="uk-modal-title">
                        <div class="uk-flex uk-flex-middle">
                            <div>Contract</div>
                            <button class="uk-button-small uk-button-default uk-margin-left" href="#guidesModal" uk-toggle><span uk-icon="info"></span> Guides</button>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Title</label>
                        <input
                            class="uk-input"
                            type="text"
                            v-model="contractForm.title">
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Type</label>
                        <select
                            name="type"
                            class="uk-select"
                            v-model="contractForm.type"
                        >
                        <option value="1" selected>Myrobin Contract</option>
                        <option value="2" >Company Contract</option>
                        </select>
                    </div>
                    <div class="uk-margin" v-if="contractForm.type == 2">
                        <label class="uk-form-label">Company</label>
                        <multiselect
                            v-model="contractForm.company_id"
                            placeholder="Type to search company"
                            label="name"
                            name="company"
                            track-by="_id"
                            :options="company_options"
                            :searchable="true"
                            :loading='isFetching'
                            :internal-search="false"
                            :options-limit="50"
                            @search-change="setCompanyValues"
                            v-validate="'required'"
                            >
                            </multiselect>
                        <span class="uk-text-small uk-text-danger" v-show="errors.has('company')">{{ errors.first('company') }}</span>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Status</label>
                        <select
                            name="type"
                            class="uk-select"
                            v-model="contractForm.status"
                        >
                        <option value="1">Draft</option>
                        <option value="2" >Post</option>
                        </select>
                    </div>
                    <div class="uk-margin">
                        <label class="uk-form-label">Contract</label>
                        <vue-editor v-model="contractForm.contract"></vue-editor>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default uk-margin-right" type="button" @click="showModal('previewModal')">Preview</button>
                    <button class="uk-button uk-button-primary" type="button" @click="saveContract()">Save</button>
                </div>
            </div>
        </div>

        <div id="previewModal" class="uk-modal-container" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-full uk-close-large" type="button" uk-close @click="hideModal('previewModal')"></button>
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Preview</h2>
                </div>
                <div v-if="contractPreview != null" class="uk-modal-body uk-background-muted" uk-overflow-auto>
                    <div class="uk-margin uk-background-default">
                        <img width="780px" src="/images/header_kontrak_majapahit.png" alt="Header kontrak" style="object-fit: cover; width: 100%;">
                        <div class="preview-contract uk-margin uk-margin-medium-left uk-margin-medium-right" v-html="contractPreview"></div>
                        <img width="780px" src="/images/footer_kontrak_majapahit.png" alt="Footer kontrak" style="object-fit: cover; width: 100%;">
                    </div>
                </div>
            </div>
        </div>

        <div id="guidesModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <div class="uk-modal-header">
                    <h2 class="uk-modal-title">Guides</h2>
                </div>
                <div class="uk-modal-body">
                    <p>Untuk mengisi Contract, terdapat beberapa variabel yang dapat berubah secara dinamis bergantung dari nilai data itu sendiri</p>
                    <p>Untuk penggunaan variabel dinamis, gantilah penulisan di dalam contract sesuai dengan catatan berikut:</p>
                    <span>Nama Mitra : <b>{name}</b></span><br>
                    <span>No. KTP Mitra : <b>{ktp}</b></span><br>
                    <span>No. Hp Mitra : <b>{phone_number}</b></span><br>
                    <span>ID Mitra : <b>{employee_id}</b></span><br>
                    <span>Jenis Kelamin : <b>{gender}</b></span><br>
                    <span>Alamat Mitra : <b>{address}</b></span><br>
                    <span>Bank milik Mitra : <b>{bank_name}</b></span><br>
                    <span>No. Rekening Bank Mitra : <b>{bank_account_number}</b></span><br>
                    <span>Nama Perusahaan : <b>{company_name}</b></span><br>
                    <span>Jenis Usaha : <b>{jenis_usaha}</b></span><br>
                    <span>Alamat Perusahaan : <b>{company_address}</b></span><br>
                    <span>Office name : <b>{office_name}</b></span><br>
                    <span>Role name : <b>{role_name}</b></span><br>
                    <span>Salary setup : <b>{salary_setup}</b></span><br>
                    <span>Fee Normal : <b>{fee_normal}</b></span><br>
                    <span>Fee Normal Unit : <b>{fee_normal_unit}</b></span><br>
                    <span>Fee Overtime : <b>{fee_overtime}</b></span><br>
                    <span>Tanggal Mitra mulai bekerja : <b>{job_start}</b></span><br>
                    <span>Tanggal Mitra akhir bekerja : <b>{job_end}</b></span><br>
                    <span>Tanggal Penandatanganan : <b>{signed_date}</b></span><br>
                    <span>TTD Contract dari Mitra : <b>{ttd}</b></span><br>
                    <p>Contoh penulisan di dalam contract adalah seperti berikut</p>
                    <i>{name}, pemegang KTP dengan NIK {ktp} beralamat pada {address} dalam hal ini bertindak atas namanya sendiri, selanjutnya disebut sebagai “Mitra”</i>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button class="uk-button uk-button-default" type="button" href="#contractModal" uk-toggle>Back</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import Pagination from '@/components/globals/Pagination';
import LoadingTable from '@/components/globals/tables/Loading';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { VueEditor } from "vue2-editor";

export default {
    data(){
        return {
            meta: {
                title: '',
                status: null,
                type: null,
                limit: 50,
                page: 1
            },
            contractForm: {
                title: '',
                type: 1,
                contract: '',
                status: 1,
                company_id: null
            },
            contractPreview: null,
            contracts: {docs: []},
            company_options: [],
            selected_contract: null,
            isEdit: false,
            isLoading: false,
            isFetching: false,
        };
    },
    components: {
        EmptyTable,
        Pagination,
        LoadingTable,
        VueEditor,
    },
    computed : {
        ...mapGetters({
            companies: 'company/companies',
        })
    },
    watch: {
        meta() {
            this.getInitContracts();
        },
    },
    async mounted() {
        await this.getInitContracts();
    },
    methods: {
        ...mapActions({
            getContractsPaginate: 'contract/getContractsPaginate',
            getContractDetail: 'contract/getContractDetail',
            createContract: 'contract/createContract',
            updateContract: 'contract/updateContract',
            getCompanies: 'company/getCompanies',
        }),
        async getInitContracts(){
            this.isLoading = true;
            this.contracts = await this.getContractsPaginate(this.meta);
            this.isLoading = false;
        },
        statusLabel(contract){
            if (contract == null) {
                return 'No Status';
            } else if (contract.status == 1) {
                return 'Draft';
            } else if (contract.status == 2) {
                return 'Posted';
            }
        },
        statusLabelClass(contract){
            if (contract == null) {
                return 'uk-label uk-label-danger uk-border-rounded';
            } else if (contract && contract.status == 1) {
                return 'uk-label uk-border-rounded';
            } else {
                return 'uk-label uk-label-success uk-border-rounded';
            }
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            data = data.replace(/<[^>]*>/g, ' ');
            return data.substring(0,26)+'...';
        },
        async showModal(modal, data=null){
            switch (modal) {
            case 'detailModal':
                this.selected_contract = data;
                await window.UIkit.modal('#detailModal').show();
                break;
            case 'previewModal':
                this.contractPreview = data.contract;
                await window.UIkit.modal('#previewModal').show();
                break;
            case 'editModal':
                this.isEdit = true;
                this.contractForm = {
                    id: data._id,
                    title: data.title,
                    type: data.type,
                    contract: data.contract,
                    status: data.status,
                    company_id: data.company_id ? {name: data.company_id.name, _id: data.company_id._id} : null
                };
                await window.UIkit.modal('#contractModal').show();
                break;
            case 'createModal':
                this.isEdit = false;
                await window.UIkit.modal('#contractModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'detailModal':
                await window.UIkit.modal('#detailModal').hide();
                this.selected_contract = null;
                break;
            case 'previewModal':
                await window.UIkit.modal('#previewModal').hide();
                break;
            case 'editModal':
                await window.UIkit.modal('#contractModal').hide();
                this.resetForm();
                this.isEdit = false;
                break;
            case 'createModal':
                await window.UIkit.modal('#contractModal').hide();
                this.resetForm();
                this.isEdit = false;
                break;
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, _id: obj._id})
            );

            this.isFetching = false;
        },
        resetForm() {
            this.contractForm = {
                title: '',
                type: 1,
                contract: '',
                status: 1,
                company_id: null
            };
        },
        async saveContract(){
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.contractForm.company_id = this.contractForm.company_id ? this.contractForm.company_id._id : null;
                const response = this.isEdit
                    ? await this.updateContract(this.contractForm)
                    : await this.createContract(this.contractForm);
                if (response && response.status === 'OK') {
                    notificationSuccess('Contract saved!');
                    this.isEdit
                        ? this.hideModal('editModal')
                        : this.hideModal('createModal');
                    await this.getInitContracts();
                } else {
                    notificationDanger('Failed to save contract.');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
    },
};
</script>

<style scoped>
/* Quilljs content styling */
.preview-contract {
    white-space: pre-wrap;
    line-height: 1.42;
}

.preview-contract >>> h1, .preview-contract >>> h2, .preview-contract >>> h3,
.preview-contract >>> h4, .preview-contract >>> h5, .preview-contract >>> h6, .preview-contract >>> p {
    margin: 0;
    padding: 0;
}

.preview-contract >>> ol, .preview-contract >>> ul {
    padding-left: 1.5em;
}

.preview-contract >>> ol li, .preview-contract >>> ul li {
    list-style-type: none;
    padding-left: 1.5em;
}

.preview-contract >>> ul>li::before {
    content: '\2022';
}

.preview-contract >>> li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right;
}

.preview-contract >>> ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;counter-increment: list-0;
}

.preview-contract >>> ol li:before {
    content: counter(list-0,decimal) '. ';
}

.preview-contract >>> ol li.ql-indent-1 {
    counter-increment: list-1;
}

.preview-contract >>> ol li.ql-indent-1:before {
    content: counter(list-1,lower-alpha) '. ';
}

.preview-contract >>> ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.preview-contract >>> ol li.ql-indent-2 {
    counter-increment: list-2;
}

.preview-contract >>> ol li.ql-indent-2:before {
    content: counter(list-2,lower-roman) '. ';
}

.preview-contract >>> ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.preview-contract >>> ol li.ql-indent-3 {
    counter-increment: list-3;
}

.preview-contract >>> ol li.ql-indent-3:before {
    content: counter(list-3,decimal) '. ';
}

.preview-contract >>> ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.preview-contract >>> .ql-indent-1 {
    padding-left: 3em;
}

.preview-contract >>> li.ql-indent-1 {
    padding-left: 4.5em;
}

.preview-contract >>> .ql-indent-2 {
    padding-left: 6em;
}

.preview-contract >>> li.ql-indent-2 {
    padding-left: 7.5em;
}

.preview-contract >>> .ql-indent-3 {
    padding-left: 9em;
}

.preview-contract >>> li.ql-indent-3 {
    padding-left: 10.5em;
}

.preview-contract >>> .ql-align-center {
    text-align: center;
}

.preview-contract >>> .ql-align-justify {
    text-align: justify;
}

.preview-contract >>> .ql-align-right {
    text-align: right;
}
</style>
